.catalog {
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 16px;
    padding: 30px 0;
    gap: 20px
}

.catalog__title {
    padding: 0;
    margin: 0;
    font-size: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.catalog__box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.catalog__list {
    margin: 0;
    padding: 30px 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    list-style-type: none;
    align-items: stretch;
    background-color: rgba(255, 255, 255, 0.637);
    box-shadow: 0 0 560px #ffffff;
    justify-content: flex-start;
    grid-area: 1 / 2 / 2 / 6;
}

.sideBar {
    margin: 0;
    background: #f5f6f8;
    height: 100%;
    border-radius: 8px;
    grid-area: 1 / 1 / 2 / 2;
}

.sideBar__box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.sideBar__title {
    font-weight: 700 !important;
    font-size: 13px !important;
}