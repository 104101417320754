.card {
    margin: 0;
    padding: 0;
    gap: 5px;
    max-width: 250px;
    max-height: 400px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
}

.card:hover {
    cursor: pointer;
}

.card_foto {
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0;
    width: 250px;
    height: 200px;
    background: white;
    border-radius: 30px;
    color: black;
    object-fit: contain;
}

.card_title {
    margin: 0;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    height: 45px;
}

.card_subtitle {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.3px;
    position: relative;
}

.card_subtitle_prices {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
}

.current_price {
    font-weight: 700;
    font-size: 20px;
}

.without_discount:before {
    content: "";
    border-bottom: 1px solid red;
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
}




.card_button_box {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.card_button {
    padding: 5px 20px 0;
    border-radius: 30px;
    border: 1px solid #7979794d;
    background-color: inherit;
    max-height: 38px;
    width: 50%;
}

.card_button:hover {
    padding: 0;
    cursor: pointer;
    transition: all linear 0.2s;
}

.deleteFromCart {
    padding: 0;
}

.deleteFromCart {
    cursor: pointer;
    transition: all linear 0.4s;
    background: linear-gradient(45deg, rgb(25, 54, 12), rgb(48, 133, 6) 44%);
    color: black;
    border: 1px solid inherit;
}


.counter {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.count {
    color: white;
    border: none;
    background-color: inherit;
    font-size: 16px;
    text-shadow: none;
    cursor: pointer;
}

.minus {
    margin-bottom: 2px;
}

.count:hover {
    cursor: pointer;
}