.form {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.form__input {
    all: unset;
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    background: #f2f2f2;
    border: 1px solid transparent;
    padding: 15px;
    border-radius: 10px;
}

.form__input_btn {
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    background-color: #f2f2f2;
    transition: background-color 0.6s;
}

.form__input_btn:hover {
    background-color: rgb(48 133 6 / 83%);
    color: white
}

.form__forgot {
    color: gray;
    text-decoration: none;
    text-align: right;
    margin: 0;
    cursor: pointer;
    font-size: 12px;
}

.form__forgot_desc {
    text-align: center;
    margin: 10px 0 0;
    color: #2ca836;
}

.form__forgot_desc:hover {
    text-decoration: underline;
}

.form__subtitle {
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 5px;
}

.form__register {
    color: gray;
    text-decoration: none;
    text-align: center;
    text-decoration: none;
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.checkbox-label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
}

.checkbox-input {
    opacity: 0;
    /* Скрываем стандартный чекбокс */
    position: absolute;
}

.checkbox__box {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.checkbox__btn {
    accent-color: rgb(48 133 6 / 83%);
    width: 25px;
    height: 25px;
}

.checkbox__label {
    font-size: 12px;
}

.checkbox__link {
    color: rgb(48 133 6 / 83%);
}