.products {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 51px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 16px;
}

.products__main-title {
    padding: 0;
    margin: 0;
    font-size: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.products__list {
    margin: 0;
    padding: 30px 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    list-style-type: none;
    align-items: stretch;
    background-color: rgba(255, 255, 255, 0.637);
    box-shadow: 0 0 560px #ffffff;
    justify-content: flex-start
}