.search-section {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
}

.desk_menu {
    padding: 0 25px;
    min-width: 200px;
    max-width: 207px;
    width: 21%;
    background: linear-gradient(225deg, rgb(25 54 12 / 69%), rgb(48 133 6 / 83%));
    border-radius: 6px;
}

#desktop-multilevel-menu {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    font-size: 11px;
    position: relative;
    z-index: 960;
}

.root-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    color: #fff;
    letter-spacing: .5px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.burger_icon {
    width: 26px;
    height: 22px;
}

.search_bar {
    min-width: 260px;
    width: 55%;
}

.search_bar_input {
    position: relative;
    background: #f5f6f8;
    border-radius: 8px;
    display: block;
    width: 100%;
}

.search_bar_form-control {
    height: 50px;
    border-radius: 10px;
    padding: 0 20px;
    border: 0;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    background: #f5f6f8;
    box-shadow: none;
    transition: background .3s, box-shadow ease-in-out .3s;
    letter-spacing: .5px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}


.search_bar_form-control:focus {
    background: #fff;
    box-shadow: 0 0 7px #dfdfdf !important;
    transition: background .3s, box-shadow ease-in-out .3s;
}

.search_bar_form-control:focus-visible {
    outline: none;
}

.search_icon {
    position: absolute;
    top: 15px;
    right: -24px;
    color: #585858
}

.contacts {
    width: 21%;
    display: flex;
    justify-content: space-around;
}

.u_phone {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    border-bottom: 1px solid transparent;
    color: #000;
    text-decoration: none;
    margin: 0 0 0 15px;
    transition: all .4s ease;
}

.u_mail {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-bottom: 1px solid #c7c7c7;
    color: #000;
    text-decoration: none;
    margin: 0 0 0 15px;
    transition: all .4s ease;
}