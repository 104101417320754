.logo {
    cursor: pointer;
    max-width: 130px;
}

.navigation {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation__list {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 25px
}

.navigation__list_icon {
    align-items: flex-start;
}

.navigation__item {
    display: block;
    width: auto;
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    transition: all .3s ease;
}

.navigation_link {
    color: #585858;
    text-decoration: none;
    text-transform: uppercase;
}


.navigation_link:hover {
    color: #2ca836;
}

.navigation__link_person {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 5px;
}

.navigation_link_shop-bag {
    display: flex;
    align-items: center;
}

.material-symbols-outlined {
    width: 26px;
    height: 26px;
}

.navigation_btn {
    padding: 0;
    font-family: "Montserrat";
    color: #585858;
    text-decoration: none;
    text-transform: uppercase;
    background-color: inherit;
    border: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.navigation_btn:hover {
    color: #2ca836;
}