#root {
    margin: 0 auto;
    padding: 0;
    font-family: "Montserrat";
    width: 100%;
    min-height: 100vh;

    -webkit-font-smoothing: antialiased;

    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;

    text-rendering: optimizeLegibility;
}

.App {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    height: 100%;
}





i.uil-shopping-bag {
    font-size: 20px;
    color: black;
}

i.uil-shopping-bag_favorite {
    margin: 0;
    padding: 0;
    align-items: center;
    color: white;
}

.category__block {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    gap: 30px;
    align-items: center;
    width: 413px;
}


.cart {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 216px;
    display: flex;
    flex-direction: column;
}

.cart__container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-bottom: 30%;
    max-width: 80%;
    height: 100%;
    background-color: #ffffff;
    position: relative;
    top: 80px;
    bottom: 50px;
    left: 10%;
    box-shadow: 0 0 15px rgba(211, 211, 211, 0.365);
}

.cart__info {
    margin: 0;
    padding: 20px;
    max-width: 50%;
    max-height: 50%;
}

.cart__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    color: #000000;
    margin: 0;
    margin-bottom: 55px;
    padding: 0;
}

@media (max-width: 768px) {
    .cart__title {
        font-weight: 400;
        font-size: 20px;
    }
}

@media (max-width: 320px) {
    .cart__title {
        font-size: 10px;
    }
}

.favorite {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 216px;
    display: flex;
    flex-direction: column;
}

.favorite__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0;
    margin: 0;
    margin-bottom: 30%;
    max-width: 80%;
    height: 100%;
    background-color: #ffffff;
    position: relative;
    top: 80px;
    bottom: 50px;
    left: 10%;
    box-shadow: 0 0 15px rgba(211, 211, 211, 0.365);
}

.favorite__info {
    margin: 0;
    padding: 20px;
    /* max-width: 50%; */
    max-height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.favorite__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    color: #000000;
    margin: 0;
    margin-bottom: 55px;
    padding: 0;
}

@media (max-width: 768px) {
    .favorite__title {
        font-weight: 400;
        font-size: 20px;
    }
}

@media (max-width: 320px) {
    .favorite__title {
        font-size: 10px;
    }
}

.btn__clearCartFavorite {
    background-color: inherit;
    border: none;
    font-size: 13px;
    line-height: 100%;
    color: #000000;
    margin: 0;
    margin-bottom: 55px;
    padding: 0;
    padding-right: 10px;
}

.btn__clearCartFavorite:hover {
    cursor: pointer;
}

.products__item-cart {
    margin: 0;
    margin-bottom: 79px;
    padding: 0;
    display: flex;
    flex-direction: row;
    max-width: 60%;
}

.products__foto-cart {
    margin: 0;
    margin-right: 38px;
    padding: 0;
    min-width: 180px;
    height: 209px;
    max-width: 50%;
    max-height: 50%;
    background: white;
    border-radius: 30px;
    color: black;
    object-fit: contain;
}

.products__container-cart {
    margin: 0;
    margin-right: 15px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 50%;
    max-height: 50%;
}

.products__title-cart {
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .products__title-cart {
        font-size: 10px;
    }
}

@media (max-width: 320px) {
    .products__title-cart {
        font-size: 7px;
    }
}

.products__button-cart {
    margin: 0 auto;
    margin-top: -25px;
    padding: 5px 10px 20px 10px;
    height: 10px;
    border-radius: 50px;
    border: 1px solid transparent;
    background: inherit;
    color: black;
}

.products__button-cart:hover {
    cursor: pointer;
    transition: all linear 0.1s;
    background: white;
    color: black;
    border: 1px solid black;
}

.products__quantity-sum {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 150px;
}

.products__quantity-cart {
    margin: 0;
    padding: 0;
    font-weight: 200;
    font-size: 10px;
    line-height: 100%;
}

.products__sum-cart {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
}

.cart__pay {
    margin: 20px;
    padding: 54px 22px;
    background: #d9f2eb;
    min-width: 300px;
    max-width: 50%;
    max-height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}

.cart__input {
    margin: 0;
    padding: 5px 0 5px 5px;
    width: 100%;
}

.cart__checkout {
    margin: 0;
    padding: 5px 0;
}

.cart__checkout:hover {
    cursor: pointer;
}

.cart__total-info {
    margin: 0;
    margin-top: 60px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 130px;
}

.cart__total {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
}

.like i {
    color: red;
}



/* Login and Register*/



/* ACCOUNT */
.account {
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
}

.account__info {
    margin: 0;
    margin-top: 40px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.account__foto {
    margin: 0;
    padding: 0;
    width: 270px;
    height: 327px;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
}

.account__name {
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.04em;
    max-width: 270px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 14px 0;
    margin-top: 60px;
    margin-bottom: 60px;
}