.index_brands {
    margin-top: 34px;
}

.brands_ul {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
    padding: 0;
    padding-bottom: 51px;
}

.brands_img {
    width: 120px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    cursor: pointer;
}

.brands_img_dividik {
    height: 30px;
    margin-top: 20px;
}