.basket {
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 16px;
    padding: 30px 0;
    gap: 20px
}

.basket__title {
    padding: 0;
    margin: 0;
    font-size: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.basket__box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.basket__scu {
    padding: 1.1em 2.2em 1.3em;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 16.2px 1.8px rgba(0, 0, 0, 0.11);
    margin-bottom: 40px;
}