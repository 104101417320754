.price__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.price {
    width: 80px;
    background-color: white;
    border: 0.5px solid #dfe0e1;
    color: #585858;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    padding: 5px;
}