.basket__box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.basket__card {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
}

.basket__card-img {
    margin: 0;
    padding: 0;
    width: 85px;
    height: 85px;
    object-fit: contain;
    grid-area: 1 / 1 / 2 / 2;
}

.basket__card-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 25px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-area: 1 / 2 / 2 / 7;
}


.basket__card-title {
    margin: 0;
    white-space: normal;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    height: 45px;
}

.basket__card-subtitle {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.3px;
    position: relative;
}

.basket__card-subtitle_prices {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
}

.basket__card-price {
    font-weight: 700;
    font-size: 20px;
}

.current_price {
    font-weight: 700;
    font-size: 20px;
}

.without_discount:before {
    content: "";
    border-bottom: 1px solid red;
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
}