.checkbox__brands {
    accent-color: rgb(48 133 6 / 83%);
    width: 13px;
    height: 13px;
    margin: 0;
    margin-right: 5px;
}

.label__brands {
    font-size: 13px;
}