.carusel_container {
    padding-top: 10px;
    max-height: 425px;
    overflow: hidden;
    border-radius: 20px;
}

.carusel_img {
    width: 100%;
    border-radius: 20px;
}