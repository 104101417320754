.modal_main-title {
    font-size: 16px;
}

.modal_main-title_auth {
    margin: 0;
    font-weight: 900;
    font-size: 22px;
    line-height: 29px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.modal_ul-list {
    list-style-type: none;
    padding-left: 20px;
}

.modal_header {
    font-size: 14px;
}

.modal_points {
    list-style-type: none;
    padding: 0;
}



.modal_desc_bold {
    font-weight: 700;

}

.modal_close {
    position: sticky;
    top: 0;
    text-align: end;
    background-color: white;
    padding-top: 20px;
    cursor: pointer;
}