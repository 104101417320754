.footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 16px;
}

.footer_box {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer_box_contacts {
    margin-bottom: 51px;
}

.footer_link {
    font-size: 16px;
    text-decoration: none;
    color: #2f78c1;
    padding: 0 20px;
    position: relative;
}

.footer_link:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    height: 12px;
    width: 1px;
    background-color: #cad3e2;
}

.footer_typography {
    margin: 0;
    font-size: 16px;
    text-decoration: none;
    color: #2f78c1;

}

.footer_typography_oferta {
    font-size: 12px;
    color: #8d8d8d;

}

.footer_link_grey {
    font-size: 14px;
    color: #8d8d8d;
}