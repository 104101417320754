.category {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 51px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 16px;
}

.category_card {
    padding: 25px;
    height: 180px;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    transition: transform .225s;
}

.category_card:hover {
    transform: scale(1.03);
}

.category_card_household {
    background: linear-gradient(225deg, rgb(25, 54, 12) 27%, rgb(48, 133, 6), rgb(237, 242, 208));
}

.category_card_cosmetics {
    background: linear-gradient(225deg, rgb(114, 47, 221) 44%, rgb(201, 111, 209), rgb(222, 201, 185));
}

.category_card_reppelents {
    background: linear-gradient(225deg, rgb(97, 47, 63) 24%, rgb(182, 55, 45), rgb(232, 185, 105));
}

.category_card_insecticides {
    background: linear-gradient(225deg, rgb(38, 81, 145), rgb(160, 160, 166), rgb(239, 239, 240));
}

.category_card_himiya {
    background: linear-gradient(225deg, rgb(10, 161, 208), rgb(91, 103, 149), rgb(226, 114, 121));
}

.category_card_shoesCosmetics {
    background: linear-gradient(225deg, rgb(177, 64, 61), rgb(58, 21, 72), rgb(137, 151, 78));
}

.category_card_catalog {
    background: linear-gradient(225deg, rgb(25, 54, 12) 27%, rgb(48, 133, 6), rgb(237, 242, 208));
    grid-area: 2 / 3 / 3 / 5;
}

.category_title {
    font-size: 20px;
    font-weight: 900;
    line-height: 20px;
    margin: 0;
    width: 110px;
    color: white;
}

.category_img {
    height: 200px;
    position: absolute;
    bottom: -10px;
    right: 15px;
}